footer.footer{
  position: relative;
  background-color: #000;
  div{
    &.footer_top{
      padding: 56px 0;
      border-bottom: 1px solid #38393a;
      @media only screen and (max-width: 991px) {
        text-align: center;
        padding:0 0 30px 0;
      }
      header{
        margin-bottom: 20px;
        color: #fff;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        letter-spacing: 2px;
        @media only screen and (max-width: 991px) {
          padding-top: 50px;
        }
      }
      nav.footer_menu{
        ul{
          li{
            margin-bottom: 17px;
            &:last-child{
              margin-bottom: 0;
            }
            a{
              font-size: 12px;
              line-height: 18px;
              color: #757575;
              transition: 0.3s all ease;
              &:hover{
                padding-left: 15px;
              }
            }
          }
        }
      }
    }
    &.footer_bottom{
      padding: 40px 0;
      font-size: 13px;
      line-height: 18px;
      color: #747474;
      text-transform: uppercase;
      @media only screen and (max-width: 991px) {
        padding: 15px 0;
      }
      div{
        @media only screen and (max-width: 991px) {
          text-align: center;
        }
        &.devs{
          text-align: right;
          @media only screen and (max-width: 991px) {
            text-align: center;
          }
          p{
            a{
              color: #747474;
              font-weight: 600;
              text-decoration: none;
            }
          }
        }
      }
    }
    &.social{
      text-align: center;
      margin: 30px 0;
      > ul{
        > li{
          display: inline-block;
          margin-right: 15px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
}