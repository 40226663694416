.your_orders {
  table {
    width:100%;
    tr {
      th {
        font-weight: bold;
        color: $font_color1;
        p{
          font-weight: inherit;
          font-size: 18px;
          @media only screen and (max-width: 991px) {
            font-size: 14px;
          }
        }
      }
      td{
        color: $font_color1;
      }
      td, th {
        font-size:14px;
        padding:15px 0;
        border-bottom:1px solid $shop-border-color;

        &.action {
          text-align: right;
          a {
            color:$shop-special-font-color_hover;
          }
        }
      }
    }
  }
}