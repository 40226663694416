.basket {
  padding-top:$shop-general-top-padding;
  padding-bottom:$shop-general-bottom-padding;

  @import "step_box";
  @import "products_list";
  @import "summary";
  @import "discount_code_box";
  @import "price_sum";
  @import "buttons";
  @import "payment_data";
  @import "payment_type";
  @import "order_confirm";

  .shop_cart_no_products {
    text-align: center;
    font-size:30px;
    color:$shop-special-font-color;
  }
}