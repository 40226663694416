.price_sum {
  text-align:right;
  display:table;
  float:right;
	
	@media only screen and (max-width: 991px) {
		float: none;
		width: 100%;
		div{
			&.title{
				text-align: left;
			}
		}
	}
	
  > div {
    width:100%;
    text-align:right;
    display:table-row;

    > div {
      display:table-cell;
      vertical-align: top;

      &.title {
        font-size:18px;
        color:$shop-font-color2;
        padding-right: 30px;
        padding-top:5px;
      }

      &.price {
        font-size:24px;
        color:$font_color1;
        font-weight: 300;
        span{
          font-weight: bold;
        }
      }
    }

    &.discount {
      opacity:0.5;
      > div {
        &.title {
          font-size:14px;
          padding-top:6px;
        }

        &.price {
          font-size:22px;
          text-decoration: line-through;
        }
      }
    }
  }
}