.shop_forms {
color:  #b4b4b4;

  .title {
    font-size:24px;
    color:$shop-special-font-color-hover;
    padding-bottom:20px;
    font-weight: 700;
    text-transform: uppercase;
    @media only screen and (max-width: 991px) {
     text-align: center;
    }
  }

  .shop_forms_box {
    @include shop_box_style();
    padding:20px;
	@media only screen and (max-width: 991px) {
		margin-bottom: 20px;
	  }
    h4 {
      font-size:18px;
      color:#b4b4b4;
      padding-bottom:14px;
      font-weight: 600;
    }
  }

  .field_title {
    font-size:14px;
    padding-left:15px;
    color:$shop-font-color;
    padding-bottom:5px;
  }

  .shop_checkbox {
    margin: 15px 0;
    padding-left:0px;
    padding-bottom:14px;
    > div {
      display:inline-block;
      vertical-align: top;
      &:last-child{
        width: calc(100% - 28px);
      }
      .checkbox_image_box {
        width:20px;
        height:20px;
        border:1px solid #59595b;
        cursor: pointer;
        position: relative;
        > div {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-repeat: no-repeat;
          background-position: center;
          display:none;
          cursor: pointer;

          &.yes {
            background-image: url(/img/check123.png);
          }

          &.on {
            display:block;
          }
        }
      }
    }

    p {
      font-size:15px;
      padding: 2px 0 0 10px;
      font-weight: 600;
    }
  }

  p.err {
    font-size:13px;
    padding-left:15px;
    color: #bb0000;
    margin-bottom:10px;
    text-align: right;
  }

  .chosen-container {
    width:100%!important;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color:  #b4b4b4;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color:  #b4b4b4;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color:  #b4b4b4;
  }
  :-moz-placeholder { /* Firefox 18- */
    color:  #b4b4b4;
  }
 
  .input {
    color:  #b4b4b4;
    border: none;
    background: #59595b;
    margin-bottom:10px;
    height: 55px;
    width: 100%;
    padding: 0 15px;
    outline: none;
    font-size: 15px;
    &.err {
      background-color: rgba(187, 0, 0, 0.05);
      border:1px solid rgba(187, 0, 0, 0.12);
    }
  }

.gradient_submit{
  margin-right: 15px;
  margin-left: auto;
  margin-top: 15px;
}

  .submit {
    outline: none;
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   z-index: 1;
   font-family: $special_font;
   border: none;
   background: transparent;
   font-size: 15px;
   font-weight: 700;
   text-transform: uppercase;
   letter-spacing: 2px;
   color: $font_color1;
   @media only screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 12px;
  }
  }

  .form_info {
    font-size:14px;
  }

}

.js_remember_password{
  color: #b4b4b4 !important;
  font-size: 15px;
  margin-top: 10px;
  display: inline-block;
}

.delivery_data{
  .chosen-container-single{
    .chosen-single{
      height: 55px;
      padding: 12px 0 0 15px;
      font-size: 15px;
      color: #b4b4b4;
      background: #59595b;
    }
    .chosen-drop{
      background: #59595b;
      .chosen-search{
        input[type="text"]{
          border: 1px solid #b4b4b4;
          color: #b4b4b4;
          font-size: 12px;
        }
      }
    }
    .chosen-results{
      li.active-result{
        color: #b4b4b4;
      }
    }
  }
}

div.holder{
  .chosen-container-single{
    .chosen-single{
      height: 55px;
      padding: 12px 0 0 15px;
      font-size: 15px;
      color: #b4b4b4;
      background: #59595b;
    }
    .chosen-drop{
      background: #59595b;
      .chosen-search{
        input[type="text"]{
          border: 1px solid #b4b4b4;
          color: #b4b4b4;
          font-size: 12px;
        }
      }
    }
    .chosen-results{
      li.active-result{
        color: #b4b4b4;
      }
    }
  }
}