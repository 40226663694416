.shop_panel_bar {

  .box {
    
    background-color:#121212;
    margin-bottom:20px;
    padding:20px;
    line-height: 24px;

    ul {
      > li {
        a {
          font-size:14px;
          color:$shop-font-color;

          &:hover {
            color:$shop-special-font-color_hover;
          }
        }
      }
    }
  }

  .title {
    font-size:18px;
    color:$shop-special-font-color;
    padding-bottom:15px;
    font-weight: bold;
  }



}