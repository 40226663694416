.order {
  overflow: hidden;
  padding-bottom:5px;
  > div {
    float:left;
  }
  .title {
    font-size: 20px;
    color: $font_color1;;
    padding-right: 10px;
    padding-top:12px;
    font-weight: 600;
    p{
      font-weight: inherit;
    }
  }

  .order_input {
    .order_box {
      float:left;

      > div {
        float:left;
      }

      input {
        border: none;
        background:#59595b;
        height:55px;
        width:94px;
        padding: 0 20px;
        font-size:20px;
        color:#ffffff;
        outline: none;
        margin-right:4px;
      }

      .order_button {
        cursor: pointer;
        margin-right: 2px;
        &:hover {
          opacity:0.8;
        }
      }
    }
  }
}