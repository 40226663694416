.products {
  padding-top:$shop-general-top-padding;
  padding-bottom:$shop-general-bottom-padding;

  .products_row {
    padding-bottom:30px;
	  
	  @media only screen and (max-width: 991px) {
		  > .col-md-4{
			  padding-bottom: 30px;
			  &:last-child{
				  padding-bottom: 0;
			  }
		  }
	  }
	  
  }
}

@import "categories";
@import "filters";
@import "left_column";
@import "product_box";
@import "sort_row";
@import "sites_row";