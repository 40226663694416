$white: #ffffff;

$font_color1:#1a171b;
$font_color2:#767676;
$accent_color:#ed1c24;

$dark_grey:#1c1c1c;

$special_font: 'Montserrat', sans-serif;
$main_font: 'Open Sans', sans-serif;

@mixin fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@mixin anim {
  transition: 0.3s all ease;
}

header.header{
  font-family: $special_font;
  img{
    max-width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 991px) {
    padding: 0;
  }
  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-position: right;
    background-repeat: no-repeat;
    position: absolute;
    top: 78px;
    right: 0;
    background-size: contain;
    @media only screen and (max-width: 1199px) {
      width: 69%;
    }
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  div{
    &.logo_box{
      padding-right: 5%;
      position: relative;
      @media only screen and (max-width: 991px) {
        padding-top: 0;
        margin-top: 15px;
        padding-bottom: 15px;
        img{
          width: 100px;
          height: auto;
        }
        div.rwd_button{
          width: 30px;
          position: absolute;
          top: 0px;
          right: 0;
          > div{
            height: 3px;
            width: 100%;
            background-color: $shop-special-font-color_hover;
            margin-bottom: 3px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &.header_top, &.header_bottom{
      @media only screen and (max-width: 991px) {
        padding: 0!important;
      }
       ul{
         //padding-top: 10px;
        text-align: right;
        @media only screen and (max-width: 1500px) {
          padding-top: 5px;
        }
        li{
          display: inline-block;
          vertical-align: middle;
          position: relative;
          text-transform: uppercase;
          &:after{
            content: '';
            position: absolute;
            top: 15%;
            height: 70%;
            border-right: 1px solid rgba(255,255,255,0.2);
          }
          &:last-child{
            &:after{
              display: none;
            }
          }
          &.langs{
            ul{
              li{
                div{
                  &.on{
                    a{
                      color:$accent_color;
                    }
                  }
                }
                &:after{
                  display: none;
                }
                a{
                  padding: 14px;
                  background-position: left 21px center;
                  text-decoration: none;
                  font-size: 13px;
                  line-height: 13px;
                  text-transform: uppercase;
                  color: $white;
                }
              }
            }
            margin-right: 40px;
            @media only screen and (max-width: 1199px) {
              margin-right: 20px;
            }
            &:after{
              display: none;
            }
          }
          &.newsletter{
            padding-right: 30px;
            @media only screen and (max-width: 1199px) {
              padding-right: 20px;
            }
            @media only screen and (max-width: 991px) {
              display: none;
            }
            p{
              cursor: pointer;
              margin-right: 30px;
              font-size: 13px;
              line-height: 13px;
              color: #d9d9d9;
              @media only screen and (max-width: 991px) {
                margin-right: 0;
              }
              header.header{
                padding-top: 18px;
                position: fixed;
                top:0;
                left:0;
                width: 100%;
                z-index: 98;
                background-color: #fff;
                @media only screen and (max-width: 991px) {
                  padding: 0;
                }
                &:after{
                  content: '';
                  display: block;
                  width: 100%;
                  height: 1px;
                  background-position: right;
                  background-repeat: no-repeat;
                  position: absolute;
                  top: 78px;
                  right: 0;
                  background-size: contain;
                  @media only screen and (max-width: 1199px) {
                    width: 69%;
                  }
                  @media only screen and (max-width: 991px) {
                    display: none;
                  }
                }
                div{
                  &.logo_box{
                    position: relative;
                    padding-bottom: 25px;
                    @media only screen and (max-width: 991px) {
                      padding-top: 0;
                      margin-top: 15px;
                      padding-bottom: 15px;
                      img{
                        width: 100px;
                        height: auto;
                      }
                      div.rwd_button{
                        width: 30px;
                        position: absolute;
                        top: 20px;
                        right: 0;
                        > div{
                          height: 3px;
                          width: 100%;
                          background-color: #000;
                          margin-bottom: 3px;
                          &:last-child{
                            margin-bottom: 0;
                          }
                        }
                      }
                    }
                  }
                  &.header_top{
                    padding:0 0 18px 0;
                  }
                }
              };
              padding-left: 35px;
              padding-top: 5px;
              padding-bottom: 5px;
              display: inline-block;
              font-weight: 400;
              background-repeat: no-repeat;
              background-position: left center;
              background-image: url('/img/shop/mail_icon.png');
            }
          }
          &.search{
            margin-right: 30px;
            @media only screen and (max-width: 1199px) {
              margin-right: 20px;
            }
            @media only screen and (max-width: 991px) {
              margin-right: 0;
              width: 100%;
              display: none;
            }
            input.input_search{
              background-color: transparent;
              margin-right:20px;
              padding-left: 33px;
              width: 88px;
              border: none;
              outline: none;
              background-position: left center;
              background-repeat: no-repeat;
              background-image: url('/img/shop/search_icon.png');
              font-size: 13px;
              line-height: 13px;
              font-weight: 400;
              color: #d9d9d9;
              height: 23px;
              text-transform: uppercase;
              @media only screen and (max-width: 991px) {
                margin-right: 0;
                padding-left: 40px;
                width: 100%;
              }
            }
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: #f5f5f6;
            }
            ::-moz-placeholder { /* Firefox 19+ */
              color: #f5f5f6;
            }
            :-ms-input-placeholder { /* IE 10+ */
              color: #f5f5f6;
            }
            :-moz-placeholder { /* Firefox 18- */
              color: #f5f5f6;
            }
          }
          &.login_register{
            padding-top:0;
            padding-bottom:0;
            @media only screen and (max-width: 991px) {
              width: 100%;
            }
            > ul{
              > li{
                display: inline-block;
                vertical-align: top;
                padding-bottom: 0;
                padding:8px 0 8px 0;
                @media only screen and (max-width: 991px) {
                  width: 50%;
                  text-align: center;
                }
                @include anim;
                &:hover{
                  background: #161616;
                }
                &:after{
                  display: none;
                }
                &.login{
                  padding-right: 0;
                  a{
                    background-image: url('/img/login_icon.png');
                    color: #f5f5f6;
                    padding: 14px 20px 14px 41px;
                    background-position: left 21px center;
                    text-decoration: none;
                    @media only screen and (max-width: 991px) {
                      padding: 20px 0 0 0;
                      background-position: center top;
                    }
                  }
                }
                &.register{
                  @media only screen and (max-width: 1199px) {
                    padding-left: 10px;
                  }
                  a{
                    background-position: left 21px center;
                    padding: 14px 20px 14px 41px;
                    color: #f5f5f6;
                    background-image: url('/img/register_icon.png');
                    text-decoration: none;
                    @media only screen and (max-width: 991px) {
                      padding: 20px 0 0 0;
                      background-position: center top;
                    }
                  }
                }
                a{
                  padding: 14px 21px;
                  font-size: 13px;
                  line-height: 13px;
                  display: inline-block;
                  padding-left: 21px;
                  background-repeat: no-repeat;
                  background-position: left center;
                  color: #6b6b6b;
                  font-weight: 400;
                  transition: 0.3s all ease;
                  @media only screen and (max-width: 1199px) {
                    font-size: 12px;
                  }
                  @media only screen and (max-width: 991px) {
                    font-size: 9px;
                    line-height: 9px;
                  }
                }

              }
            }
          }
          div{
            &.menu_box{
              padding-right: 35px;
              @media only screen and (max-width: 991px) {
                padding-right: 0;
                width: 100%;
              }
              nav.menu{
                ul{
                  li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 44px;
                    position: relative;
                    @media only screen and (max-width: 991px) {
                      width: 100%;
                      text-align: center;
                      margin-right: 0;
                      margin-bottom: 10px;
                    }
                    &:after{
                      display: none;
                    }
                    &:hover{
                      ul{
                        visibility: visible;
                        opacity: 1;
                      }
                    }
                    @media only screen and (max-width: 1199px) {
                      margin-right: 22px;
                    }
                    &:last-child{
                      margin-right: 0;
                    }
                    a{
                      font-size: 14px;
                      line-height: 14px;
                      font-family: $special_font;
                      color: #ffffff;
                      font-weight: 400;
                      @media only screen and (max-width: 1199px) {
                        
                      }
                      @media only screen and (max-width: 991px) {
                        font-size: 13px;
                        line-height: 13px;
                      }
                    }
                    .on{
                      a{
                        font-weight: 700;
                      }
                    }
                    ul{
                      opacity: 0;
                      transition: 0.3s all ease;
                      visibility: hidden;
                      position: absolute;
                      top: 100%;
                      left: -75px;
                      margin-left: auto;
                      margin-right: auto;
                      text-align: center;
                      z-index: 5;
                      width: 200px;
                      background-color: #fff;
                      border-left: 1px solid #d9dbdd;
                      border-right: 1px solid #d9dbdd;
                      border-bottom: 1px solid #d9dbdd;
                      padding: 10px;
                      z-index: 50;
                      > li{
                        width: 100%;
                        margin-right: 0;
                        padding: 0;
                        //white-space: nowrap;
                        margin-bottom: 10px;
                        &:last-child{
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
            &.basket{
              padding-left: 35px;
              padding-top:0;
              padding-bottom:0;
              @media only screen and (max-width: 991px) {
                padding-left: 0;
              }
              a{
                font-size: 13px;
                line-height: 13px;
                padding-left: 30px;
                display: inline-block;
                background-repeat: no-repeat;
                background-position: left center;
                background-image: url('/img/basket_icon.png');
                font-weight: 600;
                color: #6b6b6b;
                span{
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 13px;
                }
              }
            }
          }
        }
      }
    }
    &.header_top{
      
      background: rgba(0,0,0,0.5);
    }
  }
  .header_bottom {
    margin: 50px 0;
    @media only screen and (max-width: 991px) {
      margin: 15px 0;
    }
    ul{
      padding-top: 5px;
      li{
        &:after{
          display: none;
        }
      }
    }
  }
  .bottom_ul{
    
    @media only screen and (max-width: 991px) {
      display: none;
      text-align: center !important;
    }
    > li{
      @media only screen and (max-width: 991px) {
        width: 100% !important;
      }
    }
  }
}