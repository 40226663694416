
.shop_gallery {
  div.large{
    position:relative;
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 15px;
    border: 1px solid #d9dbdd;
    a {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      cursor:pointer;
    }
  }
  div.small_images{
    margin-bottom: 15px;
    > div{
      cursor: pointer;
      display: inline-block;
      width: 31%;
      height: 0;
      padding-bottom: 31%;
      background-repeat:no-repeat;
      background-position: center;
      background-size: cover;
      margin-right: 3.5%;
      margin-bottom: 3.5%;
      position:relative;

      &:hover {
        opacity:0.8;
      }

      border: 1px solid #d9dbdd;

      &:nth-child(3n){
        margin-right: 0;
      }

      a {
        position:absolute;
        top:0;
        left:0;
        width:0;
        height:0;
      }
    }
  }
}