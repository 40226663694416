.code_box {
  padding-top:15px;
  padding-bottom:10px;
  > div {
    display:inline-block;

    &.title {
      font-size:15px;
      color:$font_color1;
      padding-right:10px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
    }

    &.shop_code {
      position:relative;

      .code {
        font-size:14px;
        span {
          font-size:15px;
          color:$font_color1;
          font-weight: bold;
          margin-right:5px;
        }
      }
    }
  }
}