.product_panel {
  > h3 {
    font-size:30px;
    color:$font_color1;
    font-weight: 600;
    line-height:40px;
    padding-bottom:15px;
    text-transform: uppercase;
    font-family: $special_font;
    letter-spacing: 2px;
  }

  .icon_info {
    > p {
      background-size:20px auto;
      background-position:left center;
      background-repeat:no-repeat;
      padding-left:30px;
      margin-bottom:15px;

      font-size:14px;
      color:$shop-font-color;

      &.calories {
        background-image: url('/img/ico_calories.png');
      }

      &.pack_info {
        background-image: url('/img/ico_pack_info.png');
      }

      &.short_components {
        background-image: url('/img/ico_short_components.png');
      }
    }
  }
}