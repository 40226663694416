.shop_versions_tree {

  h5 {
    font-size: 18px;
    color: $font_color1;
    padding-right: 10px;
    padding-bottom:3px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .basic_versions {
    padding-bottom:20px;
    > ul {
      border-bottom: 1px dashed $shop-border-color;
      padding-bottom: 20px;

      > li {
        display: block;
        ul {
          li.tree_one_step {
            display: none;
          }
        }
      }
    }
  }

  .one_ver_box {

    .border > div {
      border-top:1px dashed $shop-border-color;
      padding-top:20px;
      margin-top:20px;
    }

    .title {
      color:$font_color1;
      font-size:15px;
      font-weight: bold;
      line-height: 20px;
      padding-top: 16px;
      @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
      }
    }

    .content {
      padding-left:22px;
      @media only screen and (max-width: 991px) {
        padding-left: 0;
      }
      > div{
        margin-bottom: 15px;
        font-size: 15px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    //&.level_0 {
    //  padding-top:0;
    //  margin-top:0;
    //  border:0;
    //}

    .chosen-container {
      text-align:left;
      font-size:12px;
      position:relative;
      bottom:3px;
      line-height: 24px;
      .chosen-single {
        height:55px;
        line-height:20px;
        font-size: 17px;
        span{
          padding-top: 7px;
        }
        div {
          top:-1px;
        }
      }
    }
  }

  .special_versions {
    padding-bottom:20px;
    .spec_vers {
      display:none;
      h5 {
        padding-bottom:10px;
      }
      select {
        width:100%;
      }
      .chosen-container {
        width:100%!important;
      }
    }
  }
}