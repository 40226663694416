.shop_filters {
    h4 {
      color:$shop-font-color;
      font-size:$filters-title-font-size;
      padding-bottom:$filters-title-padding-bottom;
      font-weight: 100;
    }

    .filter_category {
      padding-bottom:20px;
      margin-bottom:20px;
      border-bottom:1px solid rgba(255, 255, 255, 0.2);
      &:last-of-type {
        padding-bottom:0px;
        margin-bottom:0px;
        border-bottom:0;
      }
      > div {
        > p {
          font-size:16px;
          color:$shop-special-font-color;
          font-weight: 600;
          padding-bottom: 16px;
        }

        .filter_category_box {
          padding-bottom:20px;
          &:last-of-type {
            padding-bottom:0;
          }
          > p {
            font-size:13px;
            color:$shop-special-font-color;
            font-weight: 600;
            padding-bottom:10px;
          }

          .checkbox_filter {
            padding-bottom:10px;
            overflow: hidden;
            color:$shop-special-font-color;
            &:last-of-type {
              padding-bottom:0;
            }

            .checkbox_name {
              font-size:13px;
              float:left;
            }

            .checkbox_box {
              float:left;
              width:25px;
              padding-top:1px;
              > div {
                width:15px;
                height:15px;
                border:2px solid $shop-border-color;
                border-radius: 4px;
                display:none;

                &.on {
                  background-color:$shop-special-font-color;
                }
              }
            }
          }
        }
      }
    }

  .submit_btn {
    width:100%;
    text-align: center;
  }
}

.shop_slider_range {

  .range {
    margin-left:12px;
    margin-right:12px;
    margin-bottom: 19px;
    height: 8px;
    background-color:#59595b;
    > span {
      background-color: #b4b4b4;
      border: 1px solid #b4b4b4;
      cursor: pointer;
      border-radius: 3px;
      width: 2em !important;
      height: 2em !important;
      outline: none;
      top: -.7em !important;
      margin-left: -1.1em !important;

      $shadow_opacity: 0.2;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,$shadow_opacity);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,$shadow_opacity);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,$shadow_opacity);
    }
    > div {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .holder {
    overflow: hidden;
    > div {
      float:left;
      &.i_box {
        width:45%;
      }
      &.text-center {
        width:10%;
        font-size:20px;
        color:$shop-special-font-color;
        padding-top:3px;
      }
    }
  }
}

.i_box, .filter_category_box{
  input{
    width: 100%;
    height: 40px;
    background: #59595b;
    border: none;
    padding: 0 15px;
    outline: none;
    color: #ffffff;
    font-size: 15px;
  }
}

.filters{
  .submit_holder{
    height: 55px;
    width: 90%;
    position: relative;
    margin: 0 auto;
    .submit{
      outline: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: transparent;
      font-size: 13px;
      line-height: 13px;
      text-transform: uppercase;
      color: #000000;
      border: none;
      z-index: 2;
      font-weight: 600;
      left: 2px;
    }
    &:after{
      -webkit-transform: skew(-25deg);
      -moz-transform: skew(-25deg);
      -o-transform: skew(-25deg);
      content: '';
      background:#767676;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

