.order_details {
  .order_status {
    h5 {
      font-size:24px;
      color:$shop-special-font-color_hover;
      padding-bottom:10px;
    }

    h6 {
      font-size:20px;
      color:$shop-font-color;
      padding-bottom:20px;
    }
  }

  .order_det_box {
    min-height: 175px;
    //@include shop_box_style();
    border: 1px solid #d9dbdd;
    padding:20px;
    margin-bottom:30px;
    line-height: 24px;
    font-size:14px;
    colo:$shop-font-color;

    h5 {
      font-size:25px;
      color:$shop-special-font-color_hover;
      padding-bottom:10px;
      font-weight: bold;
    }
  }
}

.gradient_submit{
  &.pay_now{
    text-align: center;
    margin: 15px auto 0 auto;
    @media only screen and (max-width: 1200px) {
      width: 200px;
    }
    > a{
      position: relative;
      color: #ffffff;
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      font-size: 20px;
      padding-top: 15px;
      @media only screen and (max-width: 1200px) {
        font-size: 17px;
      }
      &:hover{
        text-decoration: none;
      }
    }
  }
}

