@import "scss_variables";

.shop_categories {
  h4 {
    color:$shop-font-color;
    font-size:$filters-title-font-size;
    padding-bottom:$filters-title-padding-bottom;
    font-weight: 100;
  }

  .filter_category {
    > div {
      > ul {
        > li {
          margin-bottom:20px;
          padding-bottom:20px;
          border-bottom:1px solid rgba(255,255,255,0.2);
          &:last-of-type {
            border-bottom:0;
            padding-bottom:0;
            margin-bottom:0;
          }
          > a {
            font-size:18px;
            color:$shop-special-font-color;
            font-weight: 600;

            p {
              font-size:14px;
              font-weight: normal;
            }
          }
          > ul {
           // padding-left:25px;
            padding-top:20px;
            > li {
              margin-bottom:12px;
              padding-left:15px;
              background: url('/img/way_point.png') left center no-repeat;
              &:last-of-type {
                padding-bottom: 0;
              }
              > a {
                font-size:14px;
                color:$shop-special-font-color;
                &:hover {
                  color:$shop-special-font-color_hover;
                }
              }
            }
          }
        }
      }
    }
  }
}