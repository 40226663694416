.product_box {
  padding:20px;
  overflow: hidden;
  position:relative;
  .bottom{
    position: relative;
    padding-top: 15px;
    &:after {
      content:"";
      border-bottom:1px solid $shop-border-color;
      left:40%;
      width:20%;
      position:absolute;
      top:0;
    }
  }
  > .promo_ico {
    z-index: 5;
    position:absolute;
    left:-50px;
    top:-30px;
    width:120px;
    height:80px;
    background-color: #000;
    text-align: center;
    color:#fff;
    font-size:13px;

    transform: rotate(315deg) ;
    -webkit-transform: rotate(315deg) ;
    -moz-transform: rotate(315deg) ;
    -o-transform: rotate(315deg) ;
    -ms-transform: rotate(315deg) ;

    &.promo_new {
      background-color:$shop-promo-new-background-color;
    }

    &.promo_promo {
      background-color:$shop-promo-promo-background-color;
    }

    &.promo_bestseller {
      background-color:$shop-promo-bestseller-background-color;
    }

    > div {
      padding-top:58px;
    }
  }

  .image {
    width:100%;
    height:150px;
    @media only screen and (max-width: 991px) {
      height: 0;
      padding-bottom: 100%;
      position: relative;
    }
    .img {
      @media only screen and (max-width: 991px) {
        position: absolute;
        top: 0;
        left: 0;
      }
      width:100%;
      height:100%;
      background-size:cover;
      background-position:center center;
      background-repeat:no-repeat;
    }
  }

  .desc {
    text-align: center;
    padding-bottom:20px;
    //height:150px;
    h3 {
      font-size:18px;
      position:relative;
      color:$shop-font-color2;
      padding:15px 0 18px 0;
      margin-bottom:15px;
      font-weight: 600;
      height:70px;

      
    }

    p {
      color:$shop-font-color3;
      font-size:12px;
      font-weight: bold;
      line-height: 18px;
    }
  }

  .bottom {
    //overflow: hidden;
    //height:50px;
    > div {
float: left;
      &.more {
        width:40%;
        padding-top: 10px;
        @media only screen and (max-width: 1500px) {
          padding-top: 0;
        }
        @media only screen and (max-width: 991px) {
          width: 100%;
          text-align: center;
          margin-bottom: 10px;
        }
        > div {
          display: inline-block;
          vertical-align: middle;

          &.ico {
            width:45px;
            position:relative;
            margin-right:6px;
            text-align: center;
            &:after {
              content:"";
              position:absolute;
              right:0;
              top:0px;
              height:100%;
              width:2px;
              background-color:$shop-border-color;
              @media only screen and (max-width: 1500px) {
                display: none;
              }
            }
          }

          &.title {
            font-size:14px;
            color:$shop-special-font-color;
            font-weight: bold;
          }
        }
      }

      &.price {
        padding-top:3px;
        width:60%;
        text-align:right;
        font-size:23px;
        color:$shop-special-font-color;
        font-family:$shop-specia-font-family;
        @media only screen and (max-width: 1500px) {
          font-size: 19px;
        }
        @media only screen and (max-width: 1200px) {
          font-size: 15px;
        }
        @media only screen and (max-width: 991px) {
          text-align: center;
          width: 100%;
        }
        .old_price {
          text-decoration: line-through;
          font-size:16px;
        }
      }
    }
  }
}