.shop_discoutcode_box {
  white-space: nowrap;
	@media only screen and (max-width: 991px) {
		white-space: normal;
		> div{
			width: 100%;
			&.title{
				padding-right: 0;
				text-align: center;
				margin-bottom: 10px;					
			}
			input{
				
				&.field_discount_codes_code{
					margin-bottom: 10px;
					height: 40px;
				}
				&.field_discount_codes_submit_discount_code{
					right: 0!important;
					bottom: 0!important;
					width: 100%;
					height: 40px!important;
				}
			}
		}
	}
  .title {
    font-size:18px;
    color:$shop-font-color2;
    display:inline-block;
    vertical-align: top;
    padding-right:15px;

    h4 {
      padding-top:7px;
      font-weight: bold;
    }
  }

  .form {
    display:inline-block;
    vertical-align: top;

    .field_discount_codes_submit_discount_code {
      background-color:#e6e6e6;
      height:35px;
      border:0;
      border-radius: 17px;
      position:relative;
      bottom:-1px;
      right:31px;
      outline:none;

      font-size:14px;
      font-weight: bold;
      padding: 0 15px 1px 15px;
      color:$shop-special-font-color;
      text-transform: lowercase;
    }
  }

  .discount_count_limit_info {
    border-radius: 4px;
  }

  .desc {
    display:inline-block;
    vertical-align: top;
    font-size:13px;
    border-left:1px solid $shop-border-color;
    padding-left:15px;
    margin-top:8px;
    color:$shop-font-color2;

    .field_discount_codes_cancel_submit_cancel {
      background-color:$shop-special-font-color;
      color:#fff;
      text-transform: lowercase;
      border:0;
      height:20px;
      border-radius: 10px;
      font-size:12px;
      margin-top:5px;
      outline: none;
    }
  }
}