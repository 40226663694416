.buttons {
  margin-top:40px;
  > div {
    padding-top:20px;
    border-top:2px solid $shop-border-color;

    /*.submit {
      position:relative;

      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      font-weight: 700;
      padding: 15px 23px 15px 23px;
      color: #ffffff;
      display: inline-block;
      position: relative;
      border-radius: 50px;
      overflow: hidden;
      width:auto;
      opacity:1;
      outline:none;

      background: rgba(41,157,206,1);
      background: -moz-linear-gradient(left, rgba(41,157,206,1) 0%, rgba(41,157,206,1) 20%, rgba(91,184,230,1) 65%, rgba(146,211,248,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(41,157,206,1)), color-stop(20%, rgba(41,157,206,1)), color-stop(65%, rgba(91,184,230,1)), color-stop(100%, rgba(146,211,248,1)));
      background: -webkit-linear-gradient(left, rgba(41,157,206,1) 0%, rgba(41,157,206,1) 20%, rgba(91,184,230,1) 65%, rgba(146,211,248,1) 100%);
      background: -o-linear-gradient(left, rgba(41,157,206,1) 0%, rgba(41,157,206,1) 20%, rgba(91,184,230,1) 65%, rgba(146,211,248,1) 100%);
      background: -ms-linear-gradient(left, rgba(41,157,206,1) 0%, rgba(41,157,206,1) 20%, rgba(91,184,230,1) 65%, rgba(146,211,248,1) 100%);
      background: linear-gradient(to right, rgba(41,157,206,1) 0%, rgba(41,157,206,1) 20%, rgba(91,184,230,1) 65%, rgba(146,211,248,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#299dce', endColorstr='#92d3f8', GradientType=1 );
    }*/
  }
}

.shop_cart_next_button{
 position: relative;
 display: inline-block;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 19px 68px;
  color: $font_color1;
  font-weight: 600;
  margin-right: 15px;
  margin-top: 20px;
  z-index: 1;
  &:after{
    content: '';
    -webkit-transform: skew(-25deg);
    -moz-transform: skew(-25deg);
    -o-transform: skew(-25deg);
    background: #ed1c24;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}