.steps {
  //@include shop_box_style();
  background: #59595b;
  overflow: hidden;
  border: 1px solid #d9dbdd;

  .step_box_parent {
    background: url('/img/shop_cart_steps.png') right center no-repeat;

    &.basket_prev {
      background: url('/img/shop_cart_steps_prev.png') right center no-repeat;
    }

    &.basket_active {
      background-color: #121212;
     // background: url('/img/shop_carts_steps_on.png') $shop-special-font-color right center no-repeat;

      .title {
        p {
          color: #fff !important;
          font-weight: 300 !important;
			@media only screen and (max-width: 991px) {
				color: #ed1c24!important;
				font-weight: 700!important;
			}
        }
      }
    }

    &:last-of-type {
      background-image: none;
    }

    .step_box {
      float: left;
      padding: 10px 0;
      width: 100%;

      > div {
        float: left;
        &.no {
          width: 25px;
          text-align: center;
          font-weight: bold;
          color: #fff;
          font-size: 24px;
          font-family: $shop-specia-font-family;
        }

        &.title {
          padding-top: 6px;
          @media only screen and (max-width: 991px) {
            padding-top: 3px;
          }
          p {
            padding-left: 15px;
            font-size: 16px;
            font-family: $shop-specia-font-family;
            color: $shop-special-font-color;
            a {
              color: $shop-special-font-color;
            }
			  @media only screen and (max-width: 1199px) {
				  font-size: 12px;
				  padding-top: 4px;
			  }
			  @media only screen and (max-width: 991px) {
				  color: #b4b4b4;
			  }
          }
        }
      }
    }
  }
}