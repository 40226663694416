.shop_cart_delivery {
  padding-top:30px;

  .shop_new_adress_select {
    padding-bottom:15px;
  }

  .adresses_list {
    > li {
      padding-top:20px;
      padding-left:15px;
      font-size:13px;
      color:$shop-font-color;
    }
  }
}
