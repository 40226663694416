$special_font: 'Montserrat', sans-serif;
$main_font: 'Open Sans', sans-serif;

$shop-font-color: #ffffff;
$shop-font-color2: #595959;
$shop-font-color3: #959595;

$shop-special-font-color: #b4b4b4;
$shop-special-font-color2: #4093bc;
$shop-special-font-color_hover: #ed1c24;

$font_color1:#1a171b;

$shop-border-color:#d9dbdd;
$shop-border-color2:#a9a9a9;
$shop-special-border-color:#c1d8e8;

$shop-specia-font-family: 'Montserrat', sans-serif;

$shop-general-top-padding: 45px;
$shop-general-bottom-padding: 45px;

$shop-promo-new-background-color: $shop-special-font-color;
$shop-promo-promo-background-color: #bc004f;
$shop-promo-bestseller-background-color: #ff9300;

$shop_dark:#121212;

@mixin shop_box_style() {
  border:1px solid $shop-border-color;
  background: $shop_dark;
}



.gradient_submit{
  width: 300px;
  height: 55px;
  position: relative;
  margin-left: 15px;
  @media only screen and (max-width: 991px) {
    margin: 0 auto;
    width: 100%;

  }
  &:after{
    content: '';
    -webkit-transform: skew(-25deg);
    -moz-transform: skew(-25deg);
    -o-transform: skew(-25deg);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $shop-special-font-color_hover;
  }
}

.shop_button {
  outline: none;
  font-family: $special_font;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;;
  border: none;
  text-transform: uppercase;
  padding: 0 0 0 30px;
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  letter-spacing: 2px;
  background: transparent;
  background-repeat: no-repeat;
  background-position: left 20px center;
  background-image: url('./../img/to_basket2.png');
 
  &:hover {
    opacity:0.8;
    color:#fff;
  }
/*
  &.cart_submit {
    background: rgba(41,157,206,1);
    background: url('/img/ico_cart_button.png') 15px 4px no-repeat, linear-gradient(to right, rgba(41,157,206,1) 0%, rgba(41,157,206,1) 20%, rgba(91,184,230,1) 65%, rgba(146,211,248,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#299dce', endColorstr='#92d3f8', GradientType=1 );
  }

  &.pdf_info {
    background: rgba(41,157,206,1);
    background: url('/img/ico_pdf_info.png') 15px 8px no-repeat, linear-gradient(to right, rgba(41,157,206,1) 0%, rgba(41,157,206,1) 20%, rgba(91,184,230,1) 65%, rgba(146,211,248,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#299dce', endColorstr='#92d3f8', GradientType=1 );
  }*/

  &.disabled {
    opacity:0.3;
  }

}

.add_to_card_info {
  position: absolute;
  width: 300px;
  bottom: -20px;
  text-align: center;
  font-size: 14px;
  color: $shop-special-font-color_hover;
}



.shop_cart_delivery_options {
  h3 {
    padding-bottom:30px;
  }

  .shop_cart_delivery_options_form {
    overflow: hidden;
    padding-bottom:30px;
  }
}

//.shop_delivery_data_box {
//  h3 {
//    margin-bottom:30px;
//  }
//
//  h4 {
//    padding-top:15px;
//    padding-bottom:15px;
//  }
//
//  .chosen-container {
//    width:100%!important;
//  }
//
//  .adresses_list {
//    font-size:14px;
//    line-height:18px;
//    padding-top:20px;
//    text-transform: uppercase;
//	  li{
//		  line-height: 36px;
//	  }
//  }
//
//  .shop_new_adress_data {
//    input[type="text"] {
//      /*width:100%;
//      padding:10px;
//      margin-bottom:3px;
//      font-size:14px;
//      outline:none;
//      border:1px solid #dee2e6;
//      border-radius: 5px;*/
//		width: 100%;
//    height: 80px;
//    padding-left: 30px;
//    outline: none;
//    border-top: none;
//    border-left: none;
//    border-right: none;
//    border-bottom: 2px solid #e2e2e2;
//    border-radius: 15px;
//    color: #717881;
//    font-family: "Helvetica Neue", sans-serif;
//    font-size: 17px;
//    outline: none;
//		margin-bottom: 10px;
//    }
//  }
//
//
//}



.backet_payment_box {
  .additional_action_buttons {
    text-align:right;
  }
}

.shop_pages_count_per_page {
  .chosen-container {
    text-align:left;
    font-size:12px;

    .chosen-single {
      height:20px;
      line-height:20px;

      div {
        top:-1px;
      }
    }
  }
}

.shop_avaibility {
  .text_info {
    font-size:15px;
    font-weight: 600;
  }
}

.loader_box {
  display:none;
  position:absolute;
  width:100%;
  height:100%;
  background-color:rgba(255,255,255,0.5);

  > div {
    display:table;
    width:100%;
    height:100%;

    > div {
      display:table-cell;
      vertical-align: middle;

      .loader {
        margin: 0 auto;
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
      }
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@import "shop/products_list/base";
@import "shop/product_details/base";
@import "shop/cart/base";
@import "shop/login_and_register/base";
@import "shop/forms/base";
@import "shop/my_account/base";
@import "shop/page_elements/base";