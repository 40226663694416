.shop_cart_delivery_options_form {
  margin-top:45px;
  border-top:1px solid $shop-border-color;
  padding-top:30px;

  h3.title {
    font-size:18px;
    color:$shop-special-font-color-hover;
    padding-bottom:20px;
  }

  .field_radio_box {
    > div {
      display:inline-block;
      vertical-align: top;
      font-size:14px;
      margin-bottom:10px;

      &.field_radio_input {
        padding-right:10px;
      }

      &.field_radio_subtitle {
        position:relative;
        top:2px;
      }
    }
  }

  .summary {
    margin-top:30px;
    padding-top:50px;
    border-top:1px solid $shop-border-color;
  }
}