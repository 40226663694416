.shop_class_sort_box {
  @include shop_box_style();
  text-align:right;
  padding:10px;
  margin-bottom:30px;
  font-size: 13px;
  color:$shop-border-color;

  .chosen-container {
    text-align:left;
    font-size:12px;
    width: 150px !important;
    .chosen-single {
      height:30px;
      font-size: 15px;
      line-height:20px;
      padding: 5px 0 0 10px;
      
		@media only screen and (max-width: 991px) {
			height: 30px;
			padding-top: 5px;
			background-position: right 15px top 12px;
			background-size: 10px;
		}
      div {
        top:-1px;
      }
    }
  }
}

.add_to_cart{
  margin-top: 15px;
  font-size: 14px;
  color: #6f7581;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: right;
  cursor: pointer;
}