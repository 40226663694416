.shop_cart_products_list  {
  border-top:1px solid $shop-border-color;
  .list_right{
    padding-top: 40px;
    @media only screen and (max-width: 991px) {
      padding-top: 0;
    }
  }
  > li {
    border-bottom:1px solid $shop-border-color;
    padding:20px 0;

    .list_left {

      > div {
        display:table-cell;
        vertical-align: top;
		
		  @media only screen and (max-width: 991px) {
			  float: none;
			  display: block;
				  &.product_image{
					  width: 100%!important;
					  height: 0;
					  padding-bottom: 100%;
					  > div{
						   background-size: cover!important;
					  }
				  }
				  &.product_name{
					  padding-left: 0!important;
					  text-align: center;
				  }
			  }
        &.product_image {
          width:130px;
          height:150px;
          position:relative;
         // @include shop_box_style();

          > div {
            position:absolute;
            width:100%;
            height:100%;
            background-size:cover;
            background-position:center center;
            background-repeat: no-repeat;
          }
        }

        &.product_name {
          padding-left:40px;
          padding-top:25px;

          h5 {
            font-size:18px;
            //color:$shop-font-color;
            padding-bottom:10px;
          }

          h6 {
            font-size:16px;
            line-height: 26px;
            color:$font_color1;
          }
        }
      }

    }

    .list_right {
      .basic_box {
        > ul {
          overflow: hidden;
          > li {
            float: left;
            //width: 25%;
            &:nth-child(1){
              width: 21.66%;
              @media only screen and (max-width: 991px) {
                width: 100%;
                margin-bottom: 15px;
              }
            }
            &:nth-child(2){
              width: 35%;
              @media only screen and (max-width: 991px) {
                width: 100%;
              }
            }
            &:nth-child(3){
              width: 21.66%;
              @media only screen and (max-width: 1200px) {
                text-align: right;
              }
              @media only screen and (max-width: 991px) {
                width: 100%;
              }
            }
            &:nth-child(4){
              width: 21.66%;
              @media only screen and (max-width: 991px) {
                width: 100%;
              }
            }
            .price {
              font-size: 20px;
              color: $font_color1;
              padding-top: 17px;

              &.discounted {
                padding-top:22px;
              }

              .old_price {
                text-decoration: line-through;
                opacity:0.7;
                font-size:12px;
              }
            }

            &.counts {
              //padding-top: 24px;
              white-space: nowrap;
              @media only screen and (max-width: 991px) {
                text-align: center;
              }
              > div {
                display: inline-block;
                vertical-align: top;

                &.count_holder {
                  input {
                    border: none;
                    background: #59595b;
                    height: 55px;
                    width: 94px;
                    padding: 0 20px;
                    font-size: 20px;
                    color: #ffffff;
                    outline: none;
                    margin-right: 4px;
                    @media only screen and (max-width: 1500px) {
                      width: 75px;
                    }
                    @media only screen and (max-width: 1200px) {
                      width: 50px;
                      margin-right: 1px;
                    }
                  }
                }

                &.count_arrs {
                  white-space: nowrap;
                  position: relative;

                  > div {
                    display: inline-block;
                    cursor: pointer;

                    &:hover {
                      opacity: 0.8;
                    }

                    &.shop_cart_page_product_count_plus {
                      background: url('/img/shop/order_plus.png') center center no-repeat;
                      width: 50px;
                      height: 55px;
                      border-right: 1px solid #fff;
                    }

                    &.shop_cart_page_product_count_minus {
                      background: url('/img/shop/order_minus.png') center center no-repeat;
                      width: 50px;
                      height: 55px;
                    }
                  }
                }
              }
            }
          }
        }
      }
		
		 @media only screen and (max-width: 991px) {
			 ul{
				  > li{
					  width: 100%;
					  float: none;
					  > div{
              text-align: center;
              
						  &.delete_button{
							  text-align: center;
						  }
					  }
				  }
			  }
		}

      .shop_cart_page_product_delete {
        //padding-top:40px;
        margin-top: 19px !important;
        width: 62px;
        min-height:20px;
        margin-left: auto;
        margin-top: 40px;
        text-align:right;
        cursor: pointer;
        font-size:14px;
        //color:#940015;
        color:#e2001a;
        font-weight: bold;
        padding-left: 28px;
        background-image:url("./../img/delete_icon.png");
        background-repeat: no-repeat;
        background-position: left;
      }

      .final_count {
        text-align: right;
        padding-top:30px;
        font-size:13px;
        font-weight: 600;
      }

      .final_price {
        text-align: right;
        font-size:14px;
        color:$font_color1;
      }

      .discount_box {
        background-color:$shop-special-font-color;
        color:#fff;
        padding:10px;
        margin-top:10px;
        border-radius: 10px;
        display: inline-block;

        > ul {
          white-space: nowrap;
          > li {
            font-size:14px;
            display:inline-block;
            vertical-align: top;
            padding: 3px 10px 0 10px;

            &.counts {
              color:#000;
              white-space: nowrap;
              width:120px;
              @media only screen and (max-width: 991px) {
                text-align: center;
              }
              > div {
                display: inline-block;
                vertical-align: top;

                &.count_holder {
                  input {
                    border:1px solid $shop-border-color;
                    height:20px;
                    border-radius: 17px;
                    font-size: 14px;
                    outline: none;
                    font-weight:600;
                    padding: 0 15px 1px 13px;
                    width:80px;
                  }
                }

                &.count_arrs {
                  white-space: nowrap;
                  position:relative;
                  right:30px;

                  > div {
                    display:inline-block;
                    cursor: pointer;

                    &:hover {
                      opacity:0.8;
                    }

                    &.shop_cart_page_product_count_plus {
                      background: url('/img/cart_ico_plus.png') #e6e6e6 center center no-repeat;
                      width: 25px;
                      height: 20px;
                      border-right: 1px solid #fff;

                      -webkit-border-top-left-radius: 17px;
                      -webkit-border-bottom-left-radius: 17px;
                      -moz-border-radius-topleft: 17px;
                      -moz-border-radius-bottomleft: 17px;
                      border-top-left-radius: 17px;
                      border-bottom-left-radius: 17px;
                    }

                    &.shop_cart_page_product_count_minus {
                      background: url('/img/cart_ico_minus.png') #e6e6e6 center center no-repeat;
                      width: 25px;
                      height: 20px;

                      -webkit-border-top-right-radius: 17px;
                      -webkit-border-bottom-right-radius: 17px;
                      -moz-border-radius-topright: 17px;
                      -moz-border-radius-bottomright: 17px;
                      border-top-right-radius: 17px;
                      border-bottom-right-radius: 17px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}