.price_box {
  padding-top:15px;
  padding-bottom:35px;
  > div {
    display:inline-block;

    &.title {
      font-size:20px;
      color:$font_color1;
      padding-right:10px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
    }

    &.shop_prices {
      position:relative;

      .old_price {
        position:absolute;
        top:-10px;
        left:0;

        text-decoration: line-through;
      }

      .price {
        font-size:14px;
        span {
          font-size:23px;
          color:$font_color1;
          font-weight: bold;
          margin-right:5px;
          @media only screen and (max-width: 1500px) {
            font-size: 19px;
          }
          @media only screen and (max-width: 1200px) {
            font-size: 15px;
          }
        }
      }
    }
  }
}