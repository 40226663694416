.shop_order_confirm {
  margin-top:45px;
  border-top:1px solid $shop-border-color;
  padding-top:30px;

  h3.title {
    font-size:18px;
    color:$shop-special-font-color-hover;
    padding-bottom:20px;
  }

  .final_info {
    //@include shop_box_style();
    padding:20px;

    .row {
      padding-bottom:20px;
      border-bottom:1px dashed $shop-border-color;
      margin-bottom:20px;

      &:last-of-type {
        padding-bottom:0px;
        border-bottom:0px;
        margin-bottom:0;
      }
    }

    .title {
      font-size:14px;
      color:$font_color1;
    }

    .desc {
      font-size:14px;
      color:$font_color1;
      padding-bottom:5px;
    }

    .old_price {
      text-decoration: line-through;
      color:$font_color1;
    }

    .price {
      font-size:18px;
      color:$font_color1;
      font-weight: bold;
    }
  }

  .summary {
    padding-top:50px;
  }
}