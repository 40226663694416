.shop_avaibility {
  padding-top: 30px;
  padding-bottom:30px;
  .title {
    color: $font_color1;
    font-size: 13px;
    font-weight: bold;
  }

  .info {
    > div {
      padding-left:5px;
    }
  }
}