.sites_row {
  @include shop_box_style();
  padding:10px;

  .shop_pages_count_per_page {
    > div {
      display:inline-block;
      vertical-align: middle;
      .chosen-container{
        height: 30px;
        .chosen-single{
          height: 30px;
          font-size: 15px;
          padding: 5px 0 0 5px;
        }
      }
		@media only screen and (max-width: 991px) {
			display: block;
			text-align: center;
			&.title{
				padding-right: 0!important;
				margin-right: 0!important;
				padding-bottom: 10px;
				margin-bottom: 10px;
				border-bottom: 1px solid #e9e9e9;
				&:after{
					display: none;
				}
			}
			&.select{
				margin-bottom: 10px;
				.chosen-container{
					width: 100%!important;
					.chosen-single{
						height: 40px;
						padding-top: 10px;
					}
				}
			}
		}
		
      &.title {
        font-size:12px;
        color:$shop-font-color3;
        padding-right:10px;
        margin-right: 10px;
        position:relative;
        padding-top:1px;

        &:after {
          display: none;
          content:"";
          position:absolute;
          right:0;
          top:2px;
          height:15px;
          width:2px;
          background-color:$shop-border-color;
        }
      }
    }
  }

  .shop_page_list {
    > ul {
      width:100%;
      text-align:right;
		
		@media only screen and (max-width: 991px) {
      text-align: center;
      padding-top: 15px;
		}
		
      > li {
        display:inline-block;
        vertical-align: top;
        font-size:12px;
        color:$shop-font-color;
        width:25px;
        text-align: center;
        padding:3px 0;
		margin-right: 10px;
		  &:last-child{
			  margin-right: 0;
		  }
        @include shop_box_style();

        &.on {
          background-color:#ed1c24;
        }

        &.page:hover {
          background-color:#ed1c24;
        }

        a {
          color:$shop-font-color;
        }
      }
    }
  }

}