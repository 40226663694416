.user_panel {
  padding-top:$shop-general-top-padding;
  padding-bottom:$shop-general-bottom-padding;

  @import "left_column";
  @import "orders_list";

  .form_box {
    @include shop_box_style();
    padding:20px;
  }

  .adress_box {
    @include shop_box_style();
    padding:20px;
    font-size:14px;
    color:$shop-font-color;
    line-height: 24px;
    h5 {
      font-size:20px;
      color:$shop-special-font-color;
      padding-bottom:10px;
    }

    p {
      padding-bottom:10px;
    }

    a {
      background-color: $shop-special-font-color;
      color: #fff;
      text-transform: lowercase;
      border: 0;
      height: 20px;
      border-radius: 10px;
      font-size: 15px;
      margin-top: 5px;
      outline: none;
      padding:2px 25px;
    }
  }

  .panel_desc {
    padding-bottom:20px;
    color:$shop-font-color;

    h3 {
      font-size:30px;
      color:$shop-special-font-color_hover;
    }

    h4 {
      font-size:24px;
      color:#230802;
    }
  }

}

.pass_changed{
  font-size: 20px;
  line-height: 30px;
  padding: 15px;
}

@import "order_details";