.product_tabs {
  .title {
    > li {
      display:inline-block;
      border:1px solid $shop-border-color;
      padding:10px 20px;
      font-size:14px;
      background-color:#fff;
      position:relative;
      top:1px;
      cursor: pointer;

      background-color:$shop-special-font-color;
      color:#fff;

      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      &.on {
        background-color:#fff;
        color:$font_color1;
        border-bottom:1px solid #fff;
      }
    }
  }

  .desc {
    border:1px solid $shop-border-color;
    padding:20px;
    line-height: 24px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    font-size:14px;

    > li {
      display:none;

      &.on {
        display:block;
      }
    }
  }
}