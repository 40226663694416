.product_details {

  @import "gallery";
  @import "product_panel";
  @import "versions_tree";
  @import "prices_box";
  @import "code_box";
  @import "avaibility_box";
  @import "chosen_count";
  @import "product_tabs";

	.product_panel{
		@media only screen and (max-width: 991px) {
			text-align: center;
			margin-bottom: 30px;
			.icon_info{
				p{
					padding-top: 30px;
					padding-left: 0;
					background-position: center top;
				}
			}
			.price_box{
				padding-bottom: 15px;
			}
			.order{
				display: inline-block;
			}
		}	
	}
	.product_tabs{
		@media only screen and (max-width: 991px) {
			.title{
				> li{
					width: 100%;
				}
			}
		}
	}
}

